export const orderData = [
  {
    itemCode: "A1",
    poNumber: "00001",
    upcCode: "A001",
    poQuantity: 1,
  },
  {
    itemCode: "A2",
    poNumber: "00002",
    upcCode: "A002",
    poQuantity: 1,
  },
  {
    itemCode: "A3",
    poNumber: "00003",
    upcCode: "A003",
    poQuantity: 1,
  },
];
